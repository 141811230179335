<template lang="pug">
Modal(:show="isShow", :close="close")
  .modal
    h1.title {{ $t(`.title`) }}

    p.message {{ $t(`.message`) }}

    form(id="send-to-manual-analysis" ref="form" @submit.prevent="submit")
      currency-field(
        name="manual_analysis[rent_value]"
        :label="$t('.fields.rentValue')"
        required
      )

      textarea-field(
        name="manual_analysis[observation]"
        :label="$t('.fields.observation')"
      )

      .files
        file-field(
          v-for="(file, index) in files"
          name="manual_analysis[files][]"
          :key="index"
          :style="file == null && { 'display': 'none' }"
          :hideLabel="index !== files.length - 1"
          :label="$t('.fields.files')"
          @input="updateFile(index, $event)"
          destroyable
        )

    .buttons-container
      button.close(@click="close") {{ $t(".buttons.close") }}

      button.button-primary(
        form="send-to-manual-analysis"
        type="submit"
        :class="submitting && 'button-progress'"
        :disabled="submitting"
      )
        | {{ $t(".buttons.submit") }}
</template>
<script>
import { ref } from 'vue'

export default {
  setup () {
    const isShow = ref(false)

    function showModal () {
      isShow.value = true
    }

    function closeModal () {
      isShow.value = false
    }

    return {
      isShow,
      showModal,
      closeModal
    }
  },

  data() {
    return {
      // i18n
      defaultI18nScope: "realEstate.components.scoreReport.manualAnalysisModal",

      submitting: false,

      files: ['']
    }
  },

  props: {
    show: { type: Boolean, default: false },
    scoreReportPath: { type: String, required: true },
    callback: { type: Function, required: true }
  },

  updated() {
    this.show ? this.showModal() : this.closeModal()
  },

  computed: {
    attachedFileNames() {
      return this.files.filter(file => !!file)
    }
  },

  methods: {
    close() {
      this.$emit('close')
    },

    formatCurrencyValue(value) {
      return value && value.replace('R$', '').trim()
    },

    submit() {
      this.submitting = true

      const formData = new FormData(this.$refs.form)

      formData.set(
        'manual_analysis[rent_value]',
        this.formatCurrencyValue(formData.get('manual_analysis[rent_value]'))
      )

      this.$http
        .post(`${this.scoreReportPath}/manual-analysis`, formData)
        .then(() => {
          this.errors = {}
          this.$notifications.clear()
          this.$notifications.info(this.$t(".notifications.submit.success"))

          this.callback()

          this.close()
        })
        .catch(() => {
          this.$notifications.error(this.$t(".notifications.submit.failure"))
        })
        .finally(() => {
          this.submitting = false
        })
    },

    updateFile(index, file) {
      this.files[index] = file

      if (this.attachedFileNames.length >= 10) return

      if (this.files[this.files.length - 1] == '') return

      this.files.push('')
    }
  }
}
</script>
<style scoped lang="scss">
@import "src/styles/base/tables.scss";

button {
  margin-bottom: 0;
}

.modal {
  background-color: $white-color;
  border-radius: 0.7rem;
  box-shadow: 0px 7px 10px 0px rgba(0,0,0,0.10);
  padding: 2rem;
  width: 95%;
  height: fit-content;

  .title {
    font-size: 2rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  .files {
    display: flex;
    flex-direction: column-reverse;
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 4rem;
    gap: 1rem;

    .close {
      color: $red-color;
      border-color: $red-color;
      background-color: $white-color;
    }

    .button-progress {
      background-image: linear-gradient(90deg, $primary-color 45%, $primary-light 45%);
      background-size: 200%;
      animation: progress 20s ease forwards;
    }

    @keyframes progress {
      from { background-position: right; }
      to { background-position: left; }
    }
  }
}
</style>
